import { AdDetails } from '../../@types/adCommon.js';
import { CreativeRenderingValidators } from '@amzn/creative-rendering-validation/src/api/blankAdDetection.js';
import { InternalSFClientAPI } from './InternalSFClientAPI.js';

declare global {
    interface Window {
        crv: CreativeRenderingValidators;
    }
}

export const validateCreativeRendering = (adDetails: AdDetails, clientApi: InternalSFClientAPI): void => {
    const metadata = `ProgramId: ${adDetails.adCreativeMetaData.adProgramId}, CreativeId: ${adDetails.adCreativeMetaData.adCreativeId}`;

    const callBlankAdValidation = async () => {
        if (typeof window?.crv?.detectAndReportBlankAd === 'function') {
            clearInterval(intervalId);
            try {
                const result = await window.crv.detectAndReportBlankAd(document.body);
                if (result) {
                    clientApi.countMetric('blankadvalidation:failure', 1, true);
                    clientApi.logError('Blank ad detected. ' + metadata);
                } else {
                    clientApi.countMetric('blankadvalidation:success', 1, true);
                }
            } catch (err) {
                clientApi.countMetric('blankadvalidation:error', 1, true);
            }
        }
    };

    const intervalId = setInterval(callBlankAdValidation, 1000);
};
