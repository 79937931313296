import { SafeframeClientImpl } from '../CommonSFSetup.js';
import { MobileSFClientAPIImplementation } from '../MobileSFClientAPIImplementation.js';
import { ClientMessageSender } from '../clientMessageSender.js';
import { ClientMessageReceiver } from '../clientMessageReceiver.js';
import { ClientApis } from '../types/types.js';
import type { AdDetails } from '../../../@types/adCommon.js';
import { DesktopSFClientAPIImplementation } from '../DesktopSFClientAPIImplementation.js';

export const createMobileSafeframeClient = (
    adDetails: AdDetails,
    messagePort: MessagePort,
): SafeframeClientImpl<MobileSFClientAPIImplementation> => {
    const cms = new ClientMessageSender(adDetails, messagePort);
    const mL = {};
    const clientAPI = new MobileSFClientAPIImplementation(cms, adDetails, mL);
    const cmr = new ClientMessageReceiver(clientAPI as unknown as ClientApis, adDetails, mL);
    return new SafeframeClientImpl(adDetails, messagePort, cms, cmr, clientAPI);
};

export const createDesktopSafeframeClient = (
    adDetails: AdDetails,
    messagePort: MessagePort,
): SafeframeClientImpl<DesktopSFClientAPIImplementation> => {
    const cms = new ClientMessageSender(adDetails, messagePort);
    const mL = {};
    const clientAPI = new DesktopSFClientAPIImplementation(cms, adDetails, mL);
    const cmr = new ClientMessageReceiver(clientAPI as unknown as ClientApis, adDetails, mL);
    return new SafeframeClientImpl(adDetails, messagePort, cms, cmr, clientAPI);
};
