const PLACEHOLDER_DEFAULT = '&pd_rd_plhdr=t';

/**
 *  HTML entity examples:
 *      Symbols
 *          shortest: &lt;
 *          longest: &Omicron;
 *
 *      Numbers
 *          (0-31 are control characters)
 *          shortest: &#32;
 *          longest: &#8721;
 *
 *  Regular Expressions
 *      Entity Numbers: (&#(0-){2};)|(&#(a-z){3};)|(&#(a-z){4};)
 *      Entity Symbols: (&(a-z){2};)|(&(a-z){3};)|(&(a-z){4};)|(&(a-z){5};)|(&(a-z){6};)|(&(a-z){7};)
 */
const REGEX_MATCH = new RegExp(
    /((&#\d{2,4};)|(&[a-z]{2};)|(&[a-z]{3,7};)|(%[a-fA-F0-9]{2})|(\W))pd_rd_plhdr(=|%3D)t((&#\d{2,4};)|(&[a-z]{2,7};)|(%[a-fA-F0-9]{2})|(\W))/,
);

/**
 * Indices must be updated if RegEx groups are modified.
 * Each left and right group has 10 subgroups.
 * String.prototype.match() call will return the results of 22 groups.
 * Main result of the left matching group will be at index 1, while
 * the primary result of the right group is stored at index 12.
 */
const LEFT_GROUP_INDEX = 1;
const MIDDLE_GROUP_INDEX = 7;
const RIGHT_GROUP_INDEX = 8;
const LEFT_GROUP_VALUE = '$' + LEFT_GROUP_INDEX;
const RIGHT_GROUP_VALUE = '$' + RIGHT_GROUP_INDEX;

/**
 * Replaces placeholders in HTML content with a specified click tracker value.
 *
 * This function substitutes the placeholder `PLACEHOLDER_DEFAULT` (`&pd_rd_plhdr=t`) in the provided
 * HTML content with the given click tracker value. It handles cases where the placeholder is surrounded
 * by HTML entities or URL encoding, ensuring that the replacement respects the format of the surrounding content.
 *
 * @param {string} clickTracker - The replacement value for the placeholder.
 *   - If the value matches the default placeholder or is empty/whitespace, no replacement is performed.
 *   - HTML entities in the value are escaped (e.g., `&` becomes `&amp;`) when appropriate.
 * @param {string} htmlContent - The HTML content where the placeholder should be replaced.
 * @returns {string} The updated HTML content with placeholders replaced.
 */
export const replaceForHtml = (clickTracker: string, htmlContent: string): string => {
    let content = htmlContent,
        replacement = clickTracker;

    if (clickTracker === PLACEHOLDER_DEFAULT || clickTracker.trim() === '') {
        return content;
    }

    replacement = replacement.replace(/&amp;/g, '&');
    if (replacement.startsWith('&')) {
        replacement = replacement.replace(/^&+/, '');
    }
    if (replacement.endsWith('&')) {
        replacement = replacement.replace(/&+$/, '');
    }

    try {
        let match;
        let executions = 0;
        do {
            executions++;
            match = content.match(REGEX_MATCH);

            if (!match) {
                break;
            }

            const left = match[LEFT_GROUP_INDEX]; // Match of main left group - not subgroups
            const right = match[RIGHT_GROUP_INDEX]; // Match of main right group - not subgroups
            const middle = match[MIDDLE_GROUP_INDEX]; // Match middle group (equals sign)

            const hasHtmlEntity =
                (left.startsWith('&') && left.endsWith(';')) || (right.startsWith('&') && right.endsWith(';'));
            const hasUrlEncoding = left.startsWith('%') && right.startsWith('%') && middle === '%3D';

            if (hasHtmlEntity) {
                content = content.replace(
                    REGEX_MATCH,
                    LEFT_GROUP_VALUE + replacement.replace(/&/g, '&amp;') + RIGHT_GROUP_VALUE,
                );
            } else if (hasUrlEncoding) {
                content = content.replace(
                    REGEX_MATCH,
                    LEFT_GROUP_VALUE + encodeURIComponent(replacement) + RIGHT_GROUP_VALUE,
                );
            } else {
                content = content.replace(REGEX_MATCH, LEFT_GROUP_VALUE + replacement + RIGHT_GROUP_VALUE);
            }
        } while (match && executions < 10);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (ex) {
        return htmlContent;
    }

    return content;
};

/**
 * Replaces placeholders in a URL with a specified click tracker value.
 *
 * This function searches for occurrences of the placeholder `pd_rd_plhdr=t` in the given URL
 * and replaces them with the specified click tracker value. It also trims leading or trailing
 * ampersands (`&` or `&amp;`) from the replacement value to avoid introducing malformed URLs.
 *
 * @param {string} clickTracker - The replacement value for the placeholder.
 *   - If the value matches the default placeholder or is empty/whitespace, no replacement is performed.
 * @param {string} url - The URL where the placeholder should be replaced.
 * @returns {string} The updated URL with placeholders replaced.
 */
export const replaceForUrl = (clickTracker: string, url: string): string => {
    if (clickTracker === PLACEHOLDER_DEFAULT || clickTracker.trim() === '') {
        return url;
    }

    const URL_PLACEHOLDER_REGEX = /pd_rd_plhdr=t/g;
    const trimAmpersands = (clickTracker: string): string => {
        return clickTracker.trim().replace(/^(&amp;|&)+|(&amp;|&)+$/g, '');
    };

    return url.replace(URL_PLACEHOLDER_REGEX, trimAmpersands(clickTracker));
};
