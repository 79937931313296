import { utf_8_atob } from '../../components/util.js';
import { AdDetails } from '../../../@types/adCommon.js';

export const getCreative = (adDetails: AdDetails): string => {
    if (!adDetails.htmlContentEncoded || adDetails.htmlContentEncoded.trim().length === 0)
        throw new Error('No html content found on a server side fetch');
    const htmlContent = adDetails.htmlContentEncodedLength
        ? adDetails.htmlContentEncoded
        : utf_8_atob(adDetails.htmlContentEncoded);
    logIfError(htmlContent, adDetails.htmlContentEncodedLength);
    return htmlContent;
};

const logIfError = (htmlContent: string, htmlContentEncodedLength: number | undefined): void => {
    if (htmlContentEncodedLength && htmlContent.length !== htmlContentEncodedLength) {
        console.debug(
            `Mismatched expected htmlContentEncoded size. Expected ${htmlContentEncodedLength} but got ${htmlContent.length}`,
        );
    }
};
