import type { AdDetails } from '../../../@types/adCommon.js';
import { ADPT_SF_METRICS_LOGGING } from '../weblabs.js';
import { API_KEY } from './aws-metric-service-api-key.js';
import { LightAdDetails } from '../../light-ad/inbound/dtos/lightAdDetails.js';

type PostMetricDTO = {
    metricName: string;
    timestamp?: number;
    count?: number;
    dimensions: PostMetricDimensionsDTO;
    xApiKey: string;
};

type PostMetricDimensionsDTO = {
    app?: string;
    siteName?: string;
    pageType?: string;
    slotName?: string;
    placementId?: string;
    mediaType?: string;
    programId?: string;
    creativeTemplateName?: string;
    deviceType?: string;
};

/**
 * @deprecated This is not live yet. Don't use.
 * SIM: https://sim.amazon.com/issues/CPP-39529
 */
export const logMetricSF = (metricName: string, adDetails: AdDetails, count?: number): void => {
    logMetric(toPostMetricDTO(metricName, count, toSFDimensions(adDetails)));
};

/**
 * @deprecated This is not live yet. Don't use.
 * SIM: https://sim.amazon.com/issues/CPP-39529
 */
export const logMetricLA = (metricName: string, lightAdDetails: LightAdDetails, count?: number): void => {
    logMetric(toPostMetricDTO(metricName, count, toLADimensions(lightAdDetails)));
};

const logMetric = (postMetricDTO: PostMetricDTO): void => {
    if (ADPT_SF_METRICS_LOGGING().isT1()) {
        navigator.sendBeacon(
            `https://e5qq6zj76g.execute-api.us-east-1.amazonaws.com/metric?x-api-key=${API_KEY}`,
            JSON.stringify(postMetricDTO),
        );
    }
};

const toPostMetricDTO = (
    metricName: string,
    count: number | undefined,
    dimensions: PostMetricDimensionsDTO,
): PostMetricDTO => ({
    metricName: metricName,
    timestamp: new Date().getTime(),
    count: count,
    dimensions: dimensions,
    xApiKey: API_KEY,
});

const toSFDimensions = (adDetails: AdDetails): PostMetricDimensionsDTO => {
    return {
        app: 'SF',
        siteName: adDetails.siteName,
        slotName: adDetails.slotName,
        pageType: adDetails.pageType,
        placementId: adDetails.placementId,
        mediaType: adDetails.mediaType,
        programId: adDetails.adCreativeMetaData?.adProgramId,
        creativeTemplateName: adDetails.adCreativeMetaData?.adCreativeTemplateName,
        deviceType: adDetails.deviceType,
    };
};

const toLADimensions = (adDetails: LightAdDetails): PostMetricDimensionsDTO => {
    return {
        app: 'LA',
        siteName: adDetails.siteName,
        slotName: adDetails.slotName,
        pageType: adDetails.pageType,
        placementId: adDetails.placementId,
        mediaType: adDetails.creative.mediaType,
        programId: adDetails.adCreativeMetaData?.adProgramId,
        creativeTemplateName: adDetails.adCreativeMetaData?.adCreativeTemplateName,
        deviceType: adDetails.deviceType,
    };
};
