const EMPTY_OBJECT_STRINGIFIED = '{}' as const;
const EMPTY_STRING = '' as const;

/**
 * This function adds a query parameter to the given URL where the value is a JSON.
 * The most common use-case is addition of `publisherJSON` to AAX URLs. eg: https://evergreen.corp.amazon.com/aax-hub/apis/bid-api/xsp/#impression
 * If the existing URL already contains this parameter key, the two JSONs are merged based on the top-level keys.
 * In case of a conflict, priority is given to the new JSON.
 *
 * eg:
 * 1. If url contains json {"key1": "value1"}, and queryParamValueObject contains {"key1":"value2"}, priority is given to queryParamValueObject.
 *    Final json is {"key1":"value2"}
 * 2. If url contains json {"key1": "value1"}, and queryParamValueObject contains {"key2":"value2"}, JSONs are merged.
 *    Final json is {"key1":"value1", "key2":"value2"}
 *
 * @param {string} url - URL where query param needs to be added.
 * @param {string} queryParamKey - key of query param that needs to be added.
 * @param {Object} queryParamValueObject - value of query param that needs to be added.
 * @returns {string} URL with query param added.
 * @throws Error if invalid inputs are provided.
 **/
export const addJSONQueryParamToURL = (
    url: string,
    queryParamKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParamValueObject: { [key: string]: any },
): string => {
    if (queryParamKey === EMPTY_STRING) {
        throw new Error(
            'Invalid queryParamKey input to addJSONQueryParamToURL: ' +
                JSON.stringify({ queryParamKey: queryParamKey }),
        );
    }
    const urlObject = new URL(url);
    const pk = urlObject.searchParams.get(queryParamKey);
    const existingJSONString = pk ? pk : EMPTY_OBJECT_STRINGIFIED;
    const existingJSONObject = JSON.parse(existingJSONString);
    const mergedJSONObject = Object.assign(existingJSONObject, queryParamValueObject);
    urlObject.searchParams.set(queryParamKey, JSON.stringify(mergedJSONObject));
    return urlObject.toString();
};
