import type { HostMessageToClient } from '../components/messaging/types.js';
import { logDebug } from '@amzn/apejs-instrumentation/src/metrics/logging.js';

export const DEFAULT_PERCOLATE_TIMEOUT = 500;

/**
 *
 * @deprecated Migrate the logic to the GPAT side so we don't need to ask the wrapper.ts
 */
export const waitForPercolateClickTrackingFromHost = async (
    mp: MessagePort,
    timeout = DEFAULT_PERCOLATE_TIMEOUT,
): Promise<string> => {
    // Can't use race here because we need to remove the event listener
    return new Promise<string>((resolve) => {
        let timeoutId = -1;
        const listener = (e: MessageEvent) => {
            const command = e.data as HostMessageToClient;
            const expectedCommand = 'percolateClickTracking';
            if (command.command !== expectedCommand)
                throw new Error(`Unexpected command ${command}, Expected ${expectedCommand}`);
            window.clearTimeout(timeoutId); // We don't want to fire the trigger now
            resolve(command.data);
        };
        // TODO Remove this after we have made sure it works
        timeoutId = window.setTimeout(() => {
            mp.removeEventListener('message', listener); // Don't want to process the next message as it won't be what we expect!
            resolve('');
            logDebug(`Did not receive percolateClickTracking within ${timeout}ms`);
        }, timeout);
        mp.addEventListener('message', listener, { once: true });
        mp.start();
    });
};
