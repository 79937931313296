import type { AdDetails } from '../../@types/adCommon.js';
import type { SafeFrameClient, WriteAdHandler } from '../components/safeFrame.js';
import { produce, produceViewableInfo } from '../components/sfAPI.js';
import { CommonApiImplementation } from './CommonApiImplementation.js';
import { ClientMessageReceiver } from './clientMessageReceiver.js';
import { ClientMessageSender } from './clientMessageSender.js';
import { disableCookieAccess, disableGeolocationApi, sendCriticalFeatureAndLoaded } from './commonSf.js';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS.js';
import { IFRAME_INIT } from '../components/counters/AD_LOAD_COUNTERS.js';
import { BODY_END } from '../components/metrics/latency-metric-type.js';
import { documentWrite, postCreativeWrite, tagRenderFlow } from './render.js';
import type { CommonSupportedCommands } from '../host/api-client/CommonSupportedCommands.js';
import { replaceForHtml } from '../components/clickTracking/replace.js';
import { DEFAULT_PERCOLATE_TIMEOUT, waitForPercolateClickTrackingFromHost } from './percolateClickTracker.js';
import {
    InterventionReportError,
    setupReportingObserver,
} from '../components/reportingObserver/setupReportingObserver.js';
import { logMetricSF } from '../components/metrics/aws-metric-service.js';
import { getCreative } from './service/get-creative.js';
import { convertToStringIfBase64 } from '../components/util.js';

declare global {
    interface Window {
        aaxInstrPixelUrl: any;
        adFeedback?: {
            initializeSponsoredLabel: (
                feedbackDivId: string,
                feedbackFormModalUrl?: string,
                isDSACompliant?: boolean,
                formLoadErrorText?: string,
                closeButtonLabel?: string,
                adFeedbackTitle?: string,
            ) => void;
        };
    }
}

export type CommonSFClientSetupState = {
    hasFiredCODPixel: boolean;
};

type InitSFClientOptions = {
    timeout?: number;
};

export class SafeframeClientImpl<ActualApiImplementation extends CommonApiImplementation> implements SafeFrameClient {
    readonly nativeWrite: typeof document.write;
    readonly nativeOpen: typeof document.open;
    renderCompleteTime: Date | null = null;
    state: CommonSFClientSetupState = {
        hasFiredCODPixel: false,
    };

    private clickTrackingParam = ''; // Containers the clickTrackingParam passed from the host

    constructor(
        protected readonly o: AdDetails,
        private readonly mp: MessagePort,
        private readonly cms: ClientMessageSender,
        readonly cmr: ClientMessageReceiver,
        readonly c: ActualApiImplementation,
    ) {
        this.nativeWrite = document.write.bind(document);
        this.nativeOpen = document.open.bind(document);
        window.onerror = this.handleErrors;

        // log heavy ad interventions. See https://developer.chrome.com/blog/heavy-ad-interventions
        setupReportingObserver(this.o, { onReport: this.logInterventionReport });
    }

    logInterventionReport = (error: InterventionReportError) => {
        // log to cloudwatch
        logMetricSF('reporting-observer:heavy-ad-intervention', this.o);

        // log to RTLA
        this.c.logFatal('heavy-ad-intervention', error);
    };

    initSFClient = async (opts: InitSFClientOptions = {}) => {
        const { timeout = DEFAULT_PERCOLATE_TIMEOUT } = opts;

        this.ensureGlobals();
        /* Disable creative access to set and get cookies: https://issues.amazon.com/issues/APEX-4377 */
        disableCookieAccess();
        /* Disable geolocation API: https://issues.amazon.com/issues/CPP-24902 */
        disableGeolocationApi();

        /*
         * Called by the creative for further mutations to the DOM
         */
        document.write = this.documentWriteDelegate;
        /*
         * Override document.open so we can detect future writes and ensure globals are set
         */
        document.open = ((...args: any[]) => {
            this.nativeOpen(...args);
            this.ensureGlobals();
        }) as typeof document.open;

        // Send clientBodyEnd after setting up the safeframe APIs
        this.c.sendLatencyMetric(BODY_END);
        this.cms.sendMessage<CommonSupportedCommands['safeFrameReady']>('safeFrameReady');
        // Wait for the Percolate Click Tracking To Come Back
        this.clickTrackingParam = await waitForPercolateClickTrackingFromHost(this.mp, timeout);
        // We don't want to start processing messages in the host message receiver until we have gotten the
        // percolate click tracking param
        this.mp.onmessage = this.cmr.receiveMessage;

        this.c.countMetric(IFRAME_INIT, 1);
        this.c.logCsaEvent(AD_LOAD_EVENTS.IFRAME_INIT);

        // The purpose of this function is to defer writing until the SFClient variable is set up
        // before the write ad html code starts loading
        window.writeAdHandler = await this.setupWriteAdHandler();

        await window.writeAdHandler();
    };

    /**
     * This should only be called once per iframe. Inserts the creative html into the iframe document's DOM.
     * Currently, we assume all video ad creatives which use the safeframe have a built-in video player.
     * VAST ads should be rendered through LightAds.
     * We expect the synthetic load event to be fired during this code path
     */
    private readonly setupWriteAdHandler = async (): Promise<WriteAdHandler> => {
        addSyntheticOnLoadListeners(this, this.o);

        return async () => {
            try {
                this.o.isNoInventory = false;
                tagRenderFlow(this.c, this.o, this.cms);
                let htmlContent = replaceForHtml(this.clickTrackingParam, getCreative(this.o));
                htmlContent = this.tryEncapsulateInWrapper(htmlContent);
                await this.documentWriteDelegate(htmlContent);
                await postCreativeWrite(this.o, this.cms, this.c.cr, this.c);
            } catch (err) {
                this.c.logError('Failed to get creative content', err as Error);
                this.c.collapseSlot();
            }
        };
    };

    ensureGlobals = () => {
        window.onerror = this.handleErrors;
        window.$sf = produce();
        window.$sfViewableInfo = produceViewableInfo();
        window.aaxInstrPixelUrl = this.o.aaxInstrPixelUrl;
    };

    /**
     * This method can be called multiple times during the lifecycle
     */
    documentWriteDelegate = async (htmlContent: string): Promise<void> => {
        await documentWrite(htmlContent, this.state, this, this.o, this.cms, this.c.cr, this.c);
    };

    /* Overwrite the window's onerror method to catch any unexpected errors
     * coming from the creative code. We will log the error and attempt to
     * show a different ad or collapse the slot.
     */
    private handleErrors: OnErrorEventHandler = (
        message: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        errorObject?: Error,
    ): boolean => {
        const err = errorObject || new Error(message.toString());
        this.c.logError(`Window.onerror triggered inside of the iframe. Src: ${source}:${lineno}:${colno}`, err);

        if (!this.renderCompleteTime) {
            this.c.collapseSlot();
        }

        // Returning true suppresses the default browser behavior
        return true;
    };

    protected tryEncapsulateInWrapper(htmlContent: string): string {
        if (this.o.creativeWrapperDivEncoded) {
            this.c.countMetric('creativeWrapperDivEncoded', 1, true);
            const creativeWrapperDiv = convertToStringIfBase64(this.o.creativeWrapperDivEncoded);
            const creativeInsertPosition = creativeWrapperDiv.lastIndexOf('</div>');
            return [
                creativeWrapperDiv.slice(0, creativeInsertPosition),
                htmlContent,
                creativeWrapperDiv.slice(creativeInsertPosition),
            ].join('');
        }

        return htmlContent;
    }
}

// We generate our own synthetic load event for the creative to hook (since the initial sf script and html already fired it)
// we expect a matching call to generateSyntheticLoadEvent to be generated
const addSyntheticOnLoadListeners = (client: SafeframeClientImpl<any>, adDetails: AdDetails) => {
    if (!adDetails.creativeSupportsTTIMeasurement) {
        window.addEventListener('load', () => sendCriticalFeatureAndLoaded(client.c), { once: true });
    }
    window.addEventListener('load', () => client.c.fireViewableLatencyMetrics(), { once: true });
};
export const generateSyntheticLoadEvent = () => {
    window.dispatchEvent(new Event('load'));
};
